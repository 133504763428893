import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Speisekarte | Forygia Pub!
			</title>
			<meta name={"description"} content={"Wo sich sportliche Leidenschaften und kulinarische Genüsse vereinen!"} />
			<meta property={"og:title"} content={"Speisekarte | Forygia Pub!"} />
			<meta property={"og:description"} content={"Wo sich sportliche Leidenschaften und kulinarische Genüsse vereinen!"} />
			<meta property={"og:image"} content={"https://forygia.com/img/home-1.jpg"} />
			<link rel={"shortcut icon"} href={"https://forygia.com/img/icon.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://forygia.com/img/icon.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://forygia.com/img/icon.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://forygia.com/img/icon.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://forygia.com/img/icon.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://forygia.com/img/icon.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://forygia.com/img/icon.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Menü
				</Text>
				<Text
					font="--lead"
					color="--darkL2"
					text-align="center"
					md-width="100%"
					max-width="950px"
				>
					Geschmacksrichtungen so aufregend wie das Spiel! Einblick in die Speisekarte von Forygia Pub
Entdecken Sie die einzigartige gastronomische Reise im Forygia Pub, wo unsere Speisekarte von der Essenz der Sportwelt inspiriert ist. Werfen Sie einen kurzen Blick darauf, aber denken Sie daran - die wahre Magie entfaltet sich erst vor Ort!
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://forygia.com/img/menu_2.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Hauptgerichte während des Spiels:
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						In Anlehnung an die packenden Momente eines jeden Spiels sind unsere Hauptgerichte der absolute Renner. Erleben Sie eine Reihe von Gerichten, die die Aufregung der zentralen Spielzeit widerspiegeln.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://forygia.com/img/menu_1.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Overtime-Genüsse:
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Jedes Spiel hat seine Wendungen, und so auch unsere Speisekarte. Tauchen Sie ein in eine Welt voller unerwarteter Aromen, perfekt für alle, die etwas Außergewöhnliches suchen.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://forygia.com/img/menu_3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Desserts zum Schlusspfiff:
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						So wie jedes Spiel mit einem denkwürdigen Ende endet, so auch unsere Desserts. Genießen Sie die süße Krönung einer perfekten Mahlzeit, die die Euphorie eines gelungenen Spiels widerspiegelt.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://forygia.com/img/menu_4.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Ein kleiner Einblick und mehr
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Was Sie hier sehen, ist nur ein Vorgeschmack auf das Erlebnis Forygia Pub. Wenn Sie zu uns kommen, erwartet Sie eine ausgedehnte Reise voller Aromen und Erfahrungen.
Für genauere Informationen, insbesondere in Bezug auf diätetische Bedürfnisse, vegane Optionen oder medizinische Verordnungen, wenden Sie sich bitte an uns. Wir sorgen dafür, dass jeder Besuch Ihren individuellen Vorlieben und Anforderungen gerecht wird.
					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section
			padding="96px 0 24px 0"
			xl-padding="80px 0 20px 0"
			lg-padding="64px 0 16px 0"
			md-padding="48px 0 8px 0"
			sm-padding="36px 0 0px 0"
			quarkly-title="Reviews-15"
		>
			<Override slot="SectionContent" max-width="800px" />
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
				border-color="--color-darkL2"
				color="--darkL2"
			>
				<Text
					font="--headline3"
					color="--darkL2"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
					border-color="--color-darkL2"
				>
					"Ich war schon in vielen Sportbars, aber The Pitch Tavern hat einen Charme wie keine andere. Die Verschmelzung globaler Sportaromen und die Kameradschaft, die ich spürte, war wirklich unübertroffen. Ich kam alleine an, verließ das Lokal aber mit einer Gruppe neu gewonnener Freunde!"
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--darkL2" margin="0px 0px 0px 0px">
					– Ella M.
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
				border-color="--color-darkL2"
				color="--darkL2"
			>
				<Text
					font="--headline3"
					color="--darkL2"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
					border-color="--color-darkL2"
				>
					"Das Essen, das Ambiente, die Energie - alles in The Pitch Tavern ist erstklassig. Was jedoch hervorsticht, ist ihr Engagement für nachhaltige Praktiken. Es ist toll, ein Spiel zu genießen, wenn man weiß, dass der Ort sich auch um den Planeten kümmert!"
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--darkL2" margin="0px 0px 0px 0px">
					– Jake R.
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
				border-color="--color-darkL2"
				color="--darkL2"
			>
				<Text
					font="--headline3"
					color="--darkL2"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
					border-color="--color-darkL2"
				>
					"Ich bin auf meiner Reise auf die Pitch Tavern gestoßen, und das war ein wunderbarer Fund! Ihr Konzept der 'Bonding Benches' ist so einzigartig. Es geht nicht nur darum, ein Spiel zu sehen, sondern eine Gemeinschaft aufzubauen. Sehr zu empfehlen."
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--darkL2" margin="0px 0px 0px 0px">
					– Sophie T.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});